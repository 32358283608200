import ReactDOM from "react-dom/client";
import {ExtensionTag, NodeExtension} from "remirror";
import {uploadFile} from "hooks/Misc";

import {formatDataSize} from "../util";

const FilePlaceholder = ({onChange}) => {
	const handleOnSelectImage = event => {
		if (event.target.classList.value.includes("file-placeholder")) {
			const input = document.createElement("input");

			input.type = "file";
			input.accept = "*";

			input.onchange = async event => {
				const file = event.target.files[0];

				if (file) {
					let formData = new FormData();

					formData.append("file", file);

					const response = await uploadFile(formData);

					if (response.success) {
						onChange({
							src: response.data.file,
							name: response.data.name,
							size: formatDataSize(response.data.size),
							type: response.data.type,
						});
					}
				}
			};

			input.click();
		}
	};

	return (
		<div className="file-placeholder" onClick={handleOnSelectImage}>
			<span>Click to select a file</span>
		</div>
	);
};

class FileUploadExtension extends NodeExtension {
	get name() {
		return "fileUpload";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				name: {default: null},
				size: {default: null},
				type: {default: null},
				src: {default: null},
				...extra.defaults(),
			},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{
					tag: "article.card",
					getAttrs: dom => ({
						name: dom.querySelector(".card-title").textContent,
						size: dom.querySelector(".card-secondary-description").textContent,
						type: dom.querySelector(".card-description").textContent,
						src: dom.querySelector(".card-download").getAttribute("href"),
						...extra.parse,
					}),
				},
				{tag: "div[data-placeholder-file]", getAttrs: extra.parse},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				const {src} = node.attrs;

				if (src) {
					return [
						"article",
						{class: "card"},
						["p", {class: "card-title"}, node.attrs.name],
						[
							"div",
							{class: "card-content"},
							["p", {class: "card-description"}, node.attrs.type],
							["p", {class: "card-secondary-description"}, node.attrs.size],
						],
						[
							"a",
							{href: node.attrs.src, class: "card-download", "aria-label": "Download " + node.attrs.name},
							[
								"img",
								{
									src: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Download-Icon.png",
									alt: "Download icon",
									width: 24,
									height: 24,
								},
							],
						],
					];
				}

				return ["div", {"data-placeholder-file": true}];
			},
		};
	}

	createNodeViews() {
		return (node, view, getPos) => {
			if (node.attrs.src) return null;

			const container = document.createElement("div");

			const root = ReactDOM.createRoot(container);

			const handleChange = newData => {
				const tr = view.state.tr.setNodeMarkup(getPos(), null, {
					...node.attrs,
					src: newData.src,
					name: newData.name,
					size: newData.size,
					type: newData.type,
				});

				view.dispatch(tr);
			};

			root.render(<FilePlaceholder onChange={handleChange} />);

			return {
				dom: container,
				update: nextNode => {
					if (nextNode.attrs.src !== node.attrs.src) {
						root.render(
							<article className="card">
								<p className="card-title">{nextNode.attrs.name}</p>
								<div className="card-content">
									<p className="card-description">{nextNode.attrs.type}</p>
									<p className="card-secondary-description">{nextNode.attrs.size}</p>
								</div>
								<a
									aria-label={"Download " + nextNode.attrs.name}
									className="card-download"
									href={nextNode.attrs.src}>
									<img
										alt="Download icon"
										height="24"
										src="https://upload.wikimedia.org/wikipedia/commons/1/1e/Download-Icon.png"
										width="24"
									/>
								</a>
							</article>,
						);
					}

					return true;
				},
			};
		};
	}
}

export const FileUpload = new FileUploadExtension();
