import * as Yup from "yup";

export const AddPostSchemaValidation = Yup.object().shape({
	tagIds: Yup.array().min(1, "Field wajib diisi!"),
	authorIds: Yup.array().min(1, "Field wajib diisi!"),
	postAccess: Yup.string().required("Field wajib diisi!"),
	excerpt: Yup.string().required("Field wajib diisi!"),
	meta_title: Yup.string().required("Field wajib diisi!"),
	meta_description: Yup.string().required("Field wajib diisi!"),
});
