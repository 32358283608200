import {useState} from "react";

export function SearchBox({rightElement, onSearch, disabled, searchPlaceholder = "Cari...", className = ""}) {
	const [keyword, setKeyword] = useState("");

	return (
		<div className={"box-white ws p15 mb-3 " + className}>
			<div className="d-flex flex-wrap justify-content-between align-items-center">
				<div className="search position-relative mb-sm-3 mb-3 mb-lg-0">
					<input
						className="form-control"
						disabled={disabled}
						placeholder={searchPlaceholder}
						type="text"
						value={keyword}
						onChange={e => setKeyword(e.target?.value)}
						onKeyDown={e => {
							if (e.key === "Enter") {
								onSearch(keyword);
							}
						}}
					/>
					<div className="search-icon" onClick={() => !disabled && onSearch(keyword)}>
						<span className="icon-ico-search" />
					</div>
				</div>

				{rightElement}
			</div>
		</div>
	);
}
