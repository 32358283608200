import {ParagraphExtension} from "remirror/extensions";

export const Paragraph = new ParagraphExtension({
	extraAttributes: {
		style: {
			default: "text-align:justify;",
			parseDOM: dom => dom.getAttribute("style"),
			toDOM: attrs => ["style", attrs.style],
		},
	},
});
