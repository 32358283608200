import _ from "lodash";
import {useCallback, useEffect, useState} from "react";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";
import {ExtensionTag, NodeExtension} from "remirror";

import {getNodeCoordinates} from "../util";

class HTMLExtension extends NodeExtension {
	get name() {
		return "html";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {html: {default: null}, ...extra.defaults()},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{tag: "div[data-placeholder-html]", getAttrs: extra.parse},
				{
					tag: "div.html-embed-container",
					getAttrs: dom => ({
						html: dom.innerHTML,
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				if (node.attrs.html) {
					const parser = new DOMParser();
					const doc = parser.parseFromString(node.attrs.html, "text/html");
					const elements = Array.from(doc.body.childNodes);
					const container = document.createElement("div");

					container.classList.add("html-embed-container");
					elements.forEach(el => container.appendChild(el));

					return container;
				}

				return ["div", {"data-placeholder-html": true, class: "embed-placeholder"}];
			},
		};
	}
}

export function HTMLField({view, state}) {
	const node = state.doc.content.content.find(item => item.type.name === "html" && !item.attrs?.html);
	const coords = getNodeCoordinates({view, state}, "html", node?.attrs?.html === null);

	const containerPos = document.querySelector(".editor-container")?.offsetTop ?? 0;

	const {top} = coords?.coords ?? {};
	const {tr} = state ?? {};
	const {dispatch} = view ?? {};

	const [value, setValue] = useState("");
	const [isBlur, setIsBlur] = useState(false);

	useEffect(() => {
		if (isBlur) {
			const from = coords.pos;

			let transaction = tr.delete(from, from + 1);

			if (!_.isEmpty(value)) {
				const newNode = state.schema.nodes.html.create({html: value}, []);

				transaction = tr
					.insert(from, newNode)
					.setSelection(tr.selection.constructor.near(tr.doc.resolve(from + 1)));
			}

			setValue("");
			setIsBlur(false);
			dispatch(transaction);
		}
	}, [coords?.pos, dispatch, isBlur, state?.schema?.nodes?.html, tr, value]);

	const handleOnBlur = useCallback(() => {
		setIsBlur(true);
	}, []);

	const handleOnChange = useCallback(val => {
		setValue(val);
	}, []);

	if (!coords || coords?.node?.attrs?.html) return null;

	return (
		<div
			className="position-absolute embed-field card overflow-hidden"
			style={{top: top - containerPos + window.scrollY}}>
			<CodeMirror
				autoFocus
				extensions={[html()]}
				height="250px"
				value={value}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
			/>
		</div>
	);
}

export const HTML = new HTMLExtension();
