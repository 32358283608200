import dayjs from "dayjs";

import {INITIAL_VALUES} from "../constant";

export const getDetailPostFormatedData = rawData => {
	if (!rawData?.data) return {};

	const initialValueKey = Object.keys({...INITIAL_VALUES, input_slug: ""});
	const detailPostData = rawData.data;
	const formatedPostData = {
		...detailPostData,
		input_slug: detailPostData?.slug ?? "",
		publishDate: detailPostData?.publishDate ? dayjs(detailPostData.publishDate).format("YYYY-MM-DD") : "",
		time: detailPostData?.time ? detailPostData.time.slice(0, -3) : "",
		tagIds: (detailPostData?.tags ?? []).map(item => ({label: `${item.tag}`, value: item.id})),
		authorIds: (detailPostData?.authors ?? []).map(item => ({
			label: `${item.firstName} ${item.lastName}`,
			value: item.id,
		})),
	};

	return initialValueKey.reduce((accu, item) => {
		if (formatedPostData[item]) {
			return {...accu, [item]: formatedPostData[item]};
		}

		return accu;
	}, INITIAL_VALUES);
};

export function formatDataSize(bytes) {
	const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
	let index = 0;

	while (bytes >= 1 && index < units.length - 1) {
		if (bytes < 1024) {
			break; // Stop conversion if the value cannot reach 1 in the next unit
		}
		bytes /= 1024;
		index++;
	}

	return `${bytes.toFixed(2)} ${units[index]}`;
}

export function getNodeCoordinates(editorView, nodeType, isSelected, nodeIndex) {
	function hasNodeType() {
		const {state, view} = editorView;
		let result = null;

		try {
			state.doc.descendants((node, pos, nodes, index) => {
				const isIndex = typeof nodeIndex === "number" ? index === nodeIndex : true;

				if (node.type.name === nodeType && isSelected && isIndex) {
					const coords = view.coordsAtPos(pos);

					result = {node, coords, pos};

					return false;
				}

				return true;
			});

			return result;
		} catch (error) {
			return null;
		}
	}

	return hasNodeType();
}
