import {ExtensionTag, NodeExtension} from "remirror";

class ToggleExtension extends NodeExtension {
	get name() {
		return "toggle";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				...extra.defaults(),
			},
			group: "block",
			content: "block+",
			selectable: true,
			parseDOM: [
				{
					tag: "details.custom-details",
					getAttrs: () => ({
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: () => ["details", {class: "custom-details", open: true}, 0],
		};
	}
}

class ToggleHeaderExtension extends NodeExtension {
	get name() {
		return "toggleHeader";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				...extra.defaults(),
			},
			group: "block",
			content: "inline*",
			parseDOM: [
				{
					tag: "summary.detail-header",
					getAttrs: () => ({
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: () => ["summary", {class: "detail-header"}, 0],
		};
	}
}

class ToggleContentExtension extends NodeExtension {
	get name() {
		return "toggleContent";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				...extra.defaults(),
			},
			group: "block",
			content: "inline*",
			parseDOM: [
				{
					tag: "div.details-content",
					getAttrs: () => ({
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: () => ["div", {class: "details-content"}, 0],
		};
	}
}

export const Toggle = new ToggleExtension();
export const ToggleHeader = new ToggleHeaderExtension();
export const ToggleContent = new ToggleContentExtension();
