import ReactDOM from "react-dom/client";
import {ExtensionTag, NodeExtension} from "remirror";
import {Tweet} from "react-tweet";
import {useToast} from "hooks/useToast";
import twitter from "services/twitter";

import {getNodeCoordinates} from "../util";

class TwitterExtension extends NodeExtension {
	get name() {
		return "twitter";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {html: {default: null}, ...extra.defaults()},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{tag: "div[data-placeholder-twitter]", getAttrs: extra.parse},
				{
					tag: "div.twitter-embed-container",
					getAttrs: dom => ({
						html: dom.innerHTML,
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				if (node.attrs.html) {
					const parser = new DOMParser();
					const doc = parser.parseFromString(node.attrs.html, "text/html");
					const elements = Array.from(doc.body.childNodes);
					const container = document.createElement("div");

					container.classList.add("twitter-embed-container");
					elements.forEach(el => container.appendChild(el));

					return container;
				}

				return ["div", {"data-placeholder-twitter": true, class: "embed-placeholder"}];
			},
		};
	}

	createNodeViews() {
		return node => {
			if (!node.attrs.html) return null;

			const container = document.createElement("div");
			const reqex = /twitter\.com\/(?:\w+)\/status\/(\d+)/;
			const match = node.attrs.html.match(reqex);
			const root = ReactDOM.createRoot(container);

			container.classList.add("twitter-embed-container");
			container.setAttribute("data-theme", "light");

			if (!match[1]) return null;

			root.render(<Tweet id={match[1]} />);

			return {
				dom: container,
				update: () => true,
			};
		};
	}
}

export function TwitterField({view, state}) {
	const {showToast} = useToast();

	const node = state.doc.content.content.find(item => item.type.name === "twitter" && !item.attrs?.html);
	const coords = getNodeCoordinates({view, state}, "twitter", node?.attrs?.html === null);

	const containerPos = document.querySelector(".editor-container")?.offsetTop ?? 0;

	const {top} = coords?.coords ?? {};
	const {tr} = state ?? {};
	const {dispatch} = view ?? {};

	const handleOnBlur = () => {
		const node = state.doc.nodeAt(coords.pos);

		if (node) {
			const from = coords.pos;
			const to = coords.pos + node.nodeSize;
			const transaction = tr.delete(from, to);

			dispatch(transaction);
		}
	};

	const handleOnKeyDown = async event => {
		if (event.key === "Enter") {
			const value = event.target.value;
			const reqex = /(?:https?:\/\/)?(?:www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+\/status\/([0-9]+)$/;
			const match = value.match(reqex);
			const url = match ? value : null;

			if (url) {
				const {data} = await twitter.get("/api/tweet", {params: {url}});

				const node = state.doc.nodeAt(coords.pos);

				if (node) {
					const to = coords.pos + node.nodeSize;
					const newNode = state.schema.nodes.twitter.create({html: data.html}, []);
					const transaction = tr
						.insert(to, newNode)
						.setSelection(tr.selection.constructor.near(tr.doc.resolve(to + 1)));

					dispatch(transaction.scrollIntoView());
				}

				return;
			}

			showToast("error", "Terjadi masalah saat mengurai URL", 3000);
		}
	};

	if (!coords || coords?.node?.attrs?.html) return null;

	return (
		<input
			autoFocus
			className="form-control position-absolute embed-field"
			placeholder="Paste URL to add embedded content..."
			style={{top: top - containerPos + window.scrollY}}
			onBlur={handleOnBlur}
			onKeyDown={handleOnKeyDown}
		/>
	);
}

export const Twitter = new TwitterExtension();
