import {SubmitButton} from "components/Button";

import {ModalContainer} from "./ModalContainer";

export function ModalConfirmation({
	visible,
	onClose,
	onReject,
	onApprove,
	title = "Konfirmasi",
	description = "Are you sure want to delete this data?",
	loading,
	confirmationModalElement,
	rejectLabel = "Cancel",
	approveLabel = "Yes",
	noReject = false,
}) {
	return (
		<ModalContainer visible={visible}>
			<div className="box-popup w550">
				<div className="d-flex justify-content-between align-items-center mb-4">
					<div className="text-bold text-dark">{title}</div>
					<div className="for-close">
						<button className="btn btn-close" disabled={loading} type="button" onClick={onClose} />
					</div>
				</div>

				<div className="popup-content">
					<div className="col-sm-12">
						{confirmationModalElement ? (
							confirmationModalElement
						) : (
							<div className=" text-bold text-grey">{description}</div>
						)}
					</div>
					<div className="d-flex justify-content-around mt-4">
						{!noReject ? (
							<button className="btn btn-red r8 w-45" disabled={loading} type="button" onClick={onReject}>
								{rejectLabel}
							</button>
						) : null}
						<SubmitButton
							className="btn btn-green r8 w-45"
							loading={loading}
							text={approveLabel}
							onClick={onApprove}
						/>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
