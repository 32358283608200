import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import _ from "lodash";
import {SearchBox} from "components/Table";
import {useUnplashImage} from "hooks/Doctor/Post";

import {ModalContainer} from "./ModalContainer";

export const ModalUnplash = forwardRef((props, ref) => {
	const contentRef = useRef();
	const onSelectFunc = useRef();

	const [visible, setVisible] = useState(false);

	const {data, filter, filterUnplash} = useUnplashImage();
	const dataChunk = _.chunk(data?.results ?? data, 3).reduce(
		(accu, items) => {
			return [
				[...accu[0], items[0]],
				[...accu[1], items[1]],
				[...accu[2], items[2]],
			];
		},
		[[], [], []],
	);

	useImperativeHandle(
		ref,
		() => ({
			show(onSelect) {
				setVisible(true);

				onSelectFunc.current = onSelect;
			},
		}),
		[],
	);

	const onClose = () => {
		setVisible(false);
	};

	const handleOnSelectImage = data => () => {
		onClose();

		if (typeof onSelectFunc.current === "function") {
			onSelectFunc.current(data);
		}
	};

	const handleChangePage = newPage => () => {
		contentRef.current?.scrollTo(0, 0);
		filterUnplash({page: filter.page + newPage, query: filter.query, per_page: filter.per_page});
	};

	const renderItem = chunkId => (items, index) => {
		const style = {
			paddingBottom: `${(items?.height / items?.width) * 100}%`,
			backgroundColor: items?.color,
		};

		return (
			<div key={`${chunkId}-item-${index}`} className="unplash-photo" style={style}>
				<img alt={items?.alt_description} className="img-fluid" src={items?.urls?.regular} />

				<div className="unplash-overlay">
					<button className="btn btn-orange r8" onClick={handleOnSelectImage(items?.urls?.full)}>
						Select Image
					</button>
				</div>
			</div>
		);
	};

	const renderChunk = (items, index) => {
		return (
			<div key={`chunk-${index}`} className="unplash-container">
				{items.map(renderItem(`chunk-${index}`))}
			</div>
		);
	};

	return (
		<ModalContainer visible={visible}>
			<div className="box-popup box-popup-unplash">
				<div className="d-flex justify-content-end align-items-center p-4">
					<SearchBox
						className="post-search-box me-3 me-md-4"
						searchPlaceholder="Search image..."
						onSearch={keyword => {
							filterUnplash({page: 1, query: keyword, per_page: 30});
						}}
					/>

					<div className="for-close mb-1">
						<button className="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>

				<div ref={contentRef} className="popup-content scroll">
					{dataChunk.map(renderChunk)}
				</div>

				<div className="d-flex justify-content-end align-items-center p-4">
					{filter.page > 1 && (
						<button className="btn" type="button" onClick={handleChangePage(-1)}>
							<span className="icon-ico-chev-left" />
						</button>
					)}

					<button className="btn" type="button" onClick={handleChangePage(1)}>
						<span className="icon-ico-chev-right" />
					</button>
				</div>
			</div>
		</ModalContainer>
	);
});
