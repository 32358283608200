export const ADD_PARAGRAPH = "paragraph";
export const ADD_QUOTE = "blockquote";
export const ADD_HEADER = "heading";
export const ADD_FILE = "fileUpload";
export const ADD_IMAGE = "imageUpload";
export const ADD_VIDEO = "videoUpload";

export const ADD_CALLOUT = "callout";
export const ADD_TOGGLE = "toggle";
export const ADD_HTML = "html";

export const ADD_YOUTUBE = "youtube";
export const ADD_TWITTER = "twitter";

export const INITIAL_VALUES = {
	userId: null,
	title: "",
	slug: "",
	content: "",
	publishDate: "",
	tagIds: [],
	authorIds: [],
	postAccess: "public",
	excerpt: "",
	thumbnail: "",
	status: "", //draf,posted,scheduled,
	time: "", //HH:mm
	meta_title: "",
	meta_description: "",
};

export const POST_ACCESS_OPTS = [
	{label: "Public", value: "public"},
	{label: "Only Me", value: "only-me"},
];

export const POST_STATUS_OPTS = [
	{label: "All", value: ""},
	{label: "Posted", value: "posted"},
	{label: "Draft", value: "draft"},
	{label: "Scheduled", value: "scheduled"},
];

export const COMMON_TOOLBAR_ITEMS = [
	{
		title: "Paragraph",
		description: "Insert new paragraph",
		image: "img/paragraph.svg",
		type: "insert",
		key: ADD_PARAGRAPH,
	},
	{
		title: "Image",
		description: "Upload, or embed with /image [url]",
		image: "img/image.svg",
		type: "insert",
		key: ADD_IMAGE,
	},
	{
		title: "Video",
		description: "Upload and play a video",
		image: "img/video.svg",
		type: "insert",
		key: ADD_VIDEO,
	},
	{
		title: "File",
		description: "Upload a downloadable file",
		image: "img/file.svg",
		type: "insert",
		key: ADD_FILE,
	},
	{
		title: "Quote",
		description: "Insert a new quote",
		image: "img/quote.svg",
		type: "toggle",
		key: ADD_QUOTE,
		payload: {class: "styled-quote"},
	},
	{
		title: "Header",
		description: "Insert a new header",
		image: "img/header.svg",
		type: "insert",
		key: ADD_HEADER,
		payload: {
			level: 2,
			style: "text-align:justify;font-size:1.5em;line-height:2.25em;font-weight:600",
		},
	},
	{
		title: "Sub Header",
		description: "Insert a new sub header",
		image: "img/header.svg",
		type: "insert",
		key: ADD_HEADER,
		payload: {
			level: 3,
			style: "text-align:justify;font-size:1.125em;line-height:1.6875em;font-weight:600",
		},
	},
];

export const FORMATTING_TOOLBAR_ITEMS = [
	{
		title: "Callout",
		description: "Info boxes that stand out",
		image: "img/callout.svg",
		type: "toggle",
		key: ADD_CALLOUT,
	},
	{
		title: "Toggle",
		description: "Add a collapsible content",
		image: "img/toggle.svg",
		type: "toggle",
		key: ADD_TOGGLE,
	},
	{
		title: "HTML",
		description: "Insert a raw HTML card",
		image: "img/html.svg",
		type: "insert",
		key: ADD_HTML,
	},
];

export const EMBEDS_TOOLBAR_ITEMS = [
	{
		title: "Twitter",
		description: "/twitter [tweet url]",
		image: "img/twitter.svg",
		type: "insert",
		key: ADD_TWITTER,
		payload: {"data-target": "twitter"},
	},
	{
		title: "Youtube",
		description: "/youtube [youtube url]",
		image: "img/youtube.svg",
		type: "insert",
		key: ADD_YOUTUBE,
		payload: {"data-target": "youtube"},
	},
	{
		title: "Instagram",
		description: "/instagram [instagram url]",
		image: "img/instagram.svg",
		type: "insert",
		// key: ADD_EMBED,
		payload: {"data-target": "instagram"},
	},
];

export const BASE_HTML =
	'<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width,initial-scale=1"><title>Blocks Example For Mobile</title><style>.responsive-image,.responsive-video{width:100%;height:auto}.styled-quote{margin:0;padding:1rem;background-color:#f9f9f9;border-left:4px solid #ccc;font-style:italic;color:#333;line-height:1.4}.alert-box{width:100%;padding:1rem;box-sizing:border-box;background-color:#f1f8ff;border:1px solid #ccc}.alert-box p{margin:0}.video-container{position:relative;padding-bottom:56.25%;height:0;overflow:hidden;max-width:100%}.video-container iframe{position:absolute;top:0;left:0;width:100%;height:100%;border:0}.custom-details{margin:1rem 0}.details-content{padding:1rem}.card{width:100%;padding:1rem;position:relative;background:#fff;border:1px solid #e8e8e8;box-sizing:border-box}.card-title{color:#25282b;font-size:16px;font-family:system-ui,-apple-system,sans-serif;font-weight:600;line-height:1.6;margin:0 0 8px 0}.card-content{display:flex;flex-direction:column;gap:.125rem;margin-top:.5rem}.card-description{color:#25282b;font-size:12px;line-height:1.5;margin:0}.card-secondary-description{color:#a0a4a8;font-size:12px;line-height:1.5;margin:0}.card-download{position:absolute;right:1rem;top:1rem;width:24px;height:24px;display:flex;justify-content:center;align-items:center}</style></head><body>CONTENT</body></html>';
